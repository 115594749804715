import React from 'react';
import Helmet from 'react-helmet';

export default function IndexPage() {
  return (
    <Helmet>
      <meta http-equiv="refresh" content="0;url=https://stepwell.com" />
    </Helmet>
  )
}
